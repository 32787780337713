import { ButtonGroup } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import defaultTheme from "../muiTheme";
import { CustomInput } from "./CustomInput";
import { LoadButton } from "./LoadButton";

export const UploadFile = ({
  chooseFileFunction,
  uploadFunction,
  file,
  loading,
  filetype,
  uploadState,
  filesProcessed
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        {/* Button to choose the file you want to update */}
        <ButtonGroup>
          <CustomInput
            disabled={loading}
            chooseFileFunction={chooseFileFunction}
            buttonStatement={t("select_invoices")}
            file={file}
            filetype={filetype}
          />
          {/* When file is chosen for uploading, button won't be disabled anymore. When pressing this, it is uploaded.
            While it is uploaded a loading circle is shown */}
          {file && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}
            >
              <LoadButton
                variant="submit"
                clickFunction={uploadFunction}
                loading={loading}
                icon="submit"
                disabled={!file}
                uploadState={uploadState}
                style={{ marginBottom: "10px" }}
                buttonStatement={t("upload")}
              />
              {loading && (
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  {t("processed_upload_count", {
                    processed_documents: filesProcessed
                  })}
                </div>
              )}{" "}
            </div>
          )}

          {/* If you want to state info about the file you are about to upload */}
          {/* {file && (
            <section>
              File details:
              <ul>
                <li>Name: {file.name}</li>
                <li>Size: {file.size} bytes</li>
              </ul>
            </section>
          )} */}
        </ButtonGroup>
      </ThemeProvider>
    </div>
  );
};
