import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../AuthProvider";

import ButtonGroup from "@mui/material/ButtonGroup";
import { useSnackbar } from "notistack";
import _ from "underscore";
import ColumnConfiguration from "../components/ColumnConfiguration";
import ColumnHeader from "../components/HomepageColumnHeader";
import HomepageGrid from "../components/HomepageGrid";
import Pagination from "../components/Pagination";
import UserDialog from "../components/UserDialogUploadInvoice";

export default function Homepage() {
    // i18n
    const { t } = useTranslation();
    // authorization
    const { request } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    // endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/invoices`;
    const endpoint_columns = `${process.env.REACT_APP_BACKEND_URL}/columns`;
    const endpoint_reject = `${process.env.REACT_APP_BACKEND_URL}/reject/`;
    const endpoint_upload = `${process.env.REACT_APP_BACKEND_URL}/upload_invoice`;

    // value whether create user dialogue window is open
    const [isDialogOpen, toggleDialogOpen] = useState(false);
    // all invoices from database
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    // all available columns
    const [allColumns, setAllColumns] = useState(null);

    // column sorting
    const [sortColumn, setSortColumn] = useState("CreateTimestamp");
    const sortColumnRef = useRef(sortColumn);
    const [sortDirection, setSortDirection] = useState("DESC");
    const sortDirectionRef = useRef(sortDirection);

    // current page
    const [pageNumber, setPageNumber] = useState(1);
    // page selected for change
    const pageNumberRef = useRef(pageNumber);
    // value for filter input inside pagination component
    const [pageFilter, setPageFilter] = useState("");
    // state whether page is being changed
    const [changingPages, setChangingPages] = useState(false);
    // number of total pages
    const [totalPages, setTotalPages] = useState(0);

    const [uniqueRecipients, setUniqueRecipients] = useState([]);

    const [uploading, setUpLoading] = useState(false);
    const [uploadState, setUploadState] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState("");

    const [files, setFiles] = useState(null);
    const [mailboxID, setMailboxID] = useState("");

    // columns displayed and used as default for GET request
    const [currentColumns, setCurrentColumns] = useState([
        { FieldName: "InvoiceNumber", DataType: "string" },
        { FieldName: "Recipient", DataType: "string" },
        { FieldName: "Sender", DataType: "string" },
        { FieldName: "CreateTimestamp", DataType: "datetime" },
        { FieldName: "DocumentStatus", DataType: "integer" },
        { FieldName: "FinishTimestamp", DataType: "datetime" }
    ]);
    const currentColumnsRef = useRef(currentColumns);

    // copy of currentColumns, changes when selection is changed in dropdown
    const filterColumnsRef = useRef(currentColumns);
    // state whether columns are being changed
    const [changingColumns, setChangingColumns] = useState(false);

    const [filterValues, setFilterValues] = useState(["", "", "", "", "", ""]);
    const filterValuesRef = useRef(filterValues);

    // set filter values reference when filter values is updated
    // for debounce
    useEffect(() => {
        filterValuesRef.current = filterValues;
    }, [filterValues]);

    const fetchData = async (init = false, changingColumns = false) => {
        if (init) {
            setLoadingData(true);
        }

        const queryString = new URLSearchParams();
        console.warn(changingColumns);
        if (!changingColumns) {
            currentColumnsRef.current.forEach((column) =>
                queryString.append("filter_column", column["FieldName"])
            );
        } else {
            filterColumnsRef.current.forEach((column) =>
                queryString.append("filter_column", column["FieldName"])
            );
            currentColumnsRef.current = filterColumnsRef.current;
        }
        queryString.append("sort_column", sortColumnRef.current);
        queryString.append("sort_direction", sortDirectionRef.current);

        queryString.append("page_number", pageNumberRef.current);
        filterValuesRef.current.forEach((value) => queryString.append("filter_value", `${value}`));

        const fullUrl = `${endpoint}?${queryString.toString()}`;
        const response = await request("get", fullUrl, null, null, [
            {
                statusCode: 400,
                type: "error",
                message: t("invalid_query_params")
            }
        ]);
        if (response?.status === 200 || response?.status === 204) {
            setData(response.data.invoices);
            setPageNumber(pageNumberRef.current);
            setTotalPages(response.data.page_count);
            setLoadingData(false);
            setTimeout(() => {
                setChangingPages(false);
            }, 1000);
            setUniqueRecipients(response.data.unique_recipients);
        } else if (response?.status === 400) {
            setLoadingData(false);
        }
    };

    const rejectInvoice = async (documentId) => {
        const response = await request("put", endpoint_reject + documentId, null, null, [
            {
                statusCode: 200,
                type: "success",
                message: t("invoice_reject_success")
            },
            {
                statusCode: 400,
                type: "error",
                message: t("invoice_reject_failure")
            }
        ]);
        if (response?.status === 200) {
            fetchData();
        }
    };

    const fetchColumns = async () => {
        const response = await request("GET", endpoint_columns, null, null, [
            {
                statusCode: 400,
                type: "error",
                message: t("columns_load_failure")
            }
        ]);
        if (response?.status === 200) {
            setAllColumns(response.data);
        }
    };
    // Open and close upload dialog
    const handleOpenDialog = () => {
        toggleDialogOpen(true);
    };
    const handleCloseDialog = () => {
        toggleDialogOpen(false);
        fetchData(false);
    };
    // initial fetch
    useEffect(() => {
        fetchColumns();
        fetchData(true);
    }, []);

    // for configuration change dropdown menu
    const handleMenuClick = (selected) => {
        filterColumnsRef.current = filterColumnsRef.current.some(
            (current) => current.FieldName === selected.FieldName
        )
            ? filterColumnsRef.current.filter((current) => current.FieldName !== selected.FieldName)
            : filterColumnsRef.current.length < 6
            ? [...filterColumnsRef.current, selected]
            : [...filterColumnsRef.current];
        setFilterValues(new Array(filterColumnsRef.current.length).fill(""));
    };

    const resetFilterColumns = () => {
        filterColumnsRef.current = currentColumns;
        sortColumnRef.current = "CreateTimestamp";
        sortDirectionRef.current = "DESC";
    };

    const handleSubmit = () => {
        sortColumnRef.current = "";
        sortDirectionRef.current = "";
        fetchData(false, true);
    };

    // declare debounce function for filter change
    const debouncedFetch = useMemo(() => {
        return _.debounce(() => {
            fetchData();
        }, 1000);
    }, []);

    // filter change function
    const handleFilterChange = async (index, value) => {
        if (index !== -1) {
            const updatedFilterValues = [...filterValues];
            if (
                typeof value === "object" &&
                value.every((date) => {
                    return date === "" || date === undefined;
                })
            ) {
                updatedFilterValues[index] = "";
            } else {
                updatedFilterValues[index] = value;
            }
            setFilterValues(updatedFilterValues);
            filterValuesRef.current = updatedFilterValues;
        }
        debouncedFetch();
    };

    // clear all filters (does not reset current columns)
    const handleClear = () => {
        setFilterValues(new Array(currentColumnsRef.current.length).fill(""));
        debouncedFetch();
    };

    // sort change function
    const handleSortChange = (fieldname) => {
        sortColumnRef.current = fieldname;
        sortDirectionRef.current = sortDirectionRef.current === "ASC" ? "DESC" : "ASC";
        setChangingColumns(true);
    };

    // call fetch when new column is being selected for sorting
    useEffect(() => {
        if (changingColumns) {
            fetchData();
        }
        setChangingColumns(false);
    }, [changingColumns]);

    // page change function
    const handlePageChange = (number, clicked) => {
        setChangingPages(true);
        if (clicked) {
            pageNumberRef.current = number;
            fetchData();
        } else {
            setTimeout(() => {
                pageNumberRef.current = number;
            }, 3000);
            debouncedFetch();
        }
    };
    const resetFiles = () => {
        setFiles(null);
    };
    // Set file to be uploaded
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFiles(Array.from(e.target.files));
        }
    };

    const handleChange = (value) => {
        setMailboxID(value);
    };

    const handleUpload = async () => {
        if (files.length > 0) {
            setUpLoading(true);
            let counter = 0;
            const files_count = files.length;
            for (const file of files) {
                counter = counter + 1;
                // Variable to print how many documents has been processed
                setUploadedFiles((prev) => `${counter} / ${files_count}`);
                const formData = new FormData();
                formData.append("file", file);
                try {
                    const response = await request(
                        "post",
                        `${endpoint_upload}/${mailboxID}`,
                        formData,
                        "multipart/form-data"
                    );
                    // when backend return no valid documents the structure is different.
                    const firstLayer = JSON.parse(response.data);
                    try {
                        const secondLayer = JSON.parse(firstLayer.detail);
                        const message = secondLayer.detail;
                        if (message === "No valid documents.") {
                            enqueueSnackbar(t("no_valid_document", { file_name: file.name }), {
                                variant: "error",
                                preventDuplicate: true
                            });
                        }
                    } catch {
                        if (Object.values(firstLayer)[0] === 200) {
                            enqueueSnackbar(t("successfull_upload", { file_name: file.name }), {
                                variant: "success",
                                preventDuplicate: true
                            });
                        } else if (Object.values(firstLayer)[0] === 500) {
                            enqueueSnackbar(t("validation_failed"), {
                                variant: "error",
                                preventDuplicate: true
                            });
                        } else if (Object.values(firstLayer)[0] === 201) {
                            enqueueSnackbar(t("invoice_rejected", { file_name: file.name }), {
                                variant: "error",
                                preventDuplicate: true
                            });
                        }
                        setUploadState("success");
                    }
                } catch (error) {
                    setUploadState("error");
                    console.error(error);
                    resetFiles();
                    setUpLoading(false);
                }
            }
            resetFiles();
            setUpLoading(false);
        }
    };
    return (
        <>
            {/* main container */}
            <Stack
                direction="column"
                spacing={1}
                alignSelf="center"
                sx={{ width: { md: "100%", lg: "90%", xl: "70%" } }}
            >
                {/* heading */}
                <Box>
                    <Typography variant="h1" className="pageHeadline">
                        {t("homepage_title")}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ pb: 1, pl: 4.5 }}>
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenDialog()}
                        >
                            {t("upload_invoices")}
                        </Button>
                    </ButtonGroup>
                </Box>
                {/* data container */}
                <Stack direction="row">
                    {/* list container */}
                    <Grid container width="100%" sx={{ justifyContent: "end" }}>
                        {loadingData && (
                            <>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px"
                                        }
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px"
                                        }
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px"
                                        }
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px"
                                        }
                                    }}
                                />
                            </>
                        )}

                        {!loadingData && (
                            <>
                                {/* list header */}
                                <Grid item width="100%">
                                    <Grid container className="homeHead" rowGap={1}>
                                        <ColumnHeader
                                            columns={currentColumnsRef.current}
                                            sortColumn={sortColumnRef.current}
                                            sortDirection={sortDirectionRef.current}
                                            onSortChange={handleSortChange}
                                            filter={filterValues}
                                            onFilterChange={handleFilterChange}
                                            onEnter={fetchData}
                                            onClearFilter={handleClear}
                                        >
                                            <Grid
                                                item
                                                md={1}
                                                lg={0.75}
                                                className="homeHead top"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}
                                            >
                                                <ColumnConfiguration
                                                    onMenuClick={handleMenuClick}
                                                    allColumns={allColumns}
                                                    filterColumns={filterColumnsRef.current}
                                                    currentColumns={currentColumnsRef.current}
                                                    onReset={resetFilterColumns}
                                                    onSubmit={() => {
                                                        handleSubmit();
                                                    }}
                                                />
                                            </Grid>
                                        </ColumnHeader>
                                    </Grid>
                                    <UserDialog
                                        uniqueRecipients={uniqueRecipients}
                                        open={isDialogOpen}
                                        handleClose={() => handleCloseDialog()}
                                        handleUpload={handleUpload}
                                        handleChange={handleChange}
                                        handleFileChange={handleFileChange}
                                        loading={uploading}
                                        uploadState={uploadState}
                                        files={files}
                                        filesProcessed={uploadedFiles}
                                    />
                                </Grid>

                                {data && data.length > 0 ? (
                                    // list entries
                                    data.map((invoice, index) => {
                                        return (
                                            <HomepageGrid
                                                columns={currentColumnsRef.current}
                                                data={invoice}
                                                changing={changingPages}
                                                onReject={rejectInvoice}
                                                key={index}
                                            />
                                        );
                                    })
                                ) : (
                                    <Stack alignItems="center" width="100%" mt={2}>
                                        <Typography variant="body1" className="home">
                                            {t("no_documents")}
                                        </Typography>
                                    </Stack>
                                )}
                                {totalPages > 0 && (
                                    // pagination
                                    <Pagination
                                        currentPage={pageNumber}
                                        selectedPage={pageNumberRef.current}
                                        changing={changingPages}
                                        filterValue={pageFilter}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                        onFilterChange={setPageFilter}
                                        onEnter={fetchData}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}
