import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import PublishIcon from "@mui/icons-material/Publish";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, ThemeProvider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import defaultTheme from "../muiTheme";
/**
 *
 * @param {string | null} variant - 'text', 'outline' or `null` for 'contained'
 * @param {function} onClick - on-click function
 * @param {string | null} color - 'secondary' or `null` for 'primary'
 * @param {string | null} icon - string with type of icon, e.g. 'add', 'save', etc. - `null` for no icon
 * @param {string | null} label - text inside button
 * @param {boolean | null} disabled - toggles whether button is clickable or not
 *
 * @returns custom button with pre-defined icons
 */
const CustomButton = ({ variant, onClick, color, icon, label, disabled, customStyle }) => {
  const [c, setC] = useState("");

  useEffect(() => {
    setC(color === undefined ? "primary" : color);
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Button
        variant={variant === undefined || variant === "submit" ? "contained" : variant}
        onClick={onClick}
        className={
          (variant === "outline"
            ? `${c}` + "-outlined"
            : variant === "text"
            ? `${c}` + "-text" + (icon === undefined ? " wo-icon" : "")
            : variant === "overview"
            ? "overview-text wo-icon"
            : variant === "submit"
            ? `${c}` + (icon === "load" ? "" : label ? "" : " wo-label")
            : `${c}`) + (icon !== undefined ? (variant !== "submit" ? " with-icon" : "") : "")
        }
        disabled={disabled === undefined ? false : disabled}
        style={{ ...customStyle }}
      >
        {icon === "add" && <AddIcon className="button-icon" />}
        {icon === "cancel" && <CancelIcon className="button-icon" />}
        {icon === "refresh" && <RefreshIcon className="button-icon" />}
        {icon === "reset" && <RestartAltIcon className="button-icon" />}
        {icon === "save" && <SaveIcon className="button-icon" />}
        {icon === "train" && <ModelTrainingIcon className="button-icon" />}
        {icon === "skip" && <SkipNextIcon className="button-icon" />}
        {icon === "load" && <CircularProgress size={26} className="loading-icon" />}
        {icon === "success" && <CheckCircleIcon size={24.5} className="success-icon" />}
        {icon === "upload" && <UploadIcon className="button-icon" />}
        {icon === "delete" && <DeleteIcon className="button-icon" />}
        {icon === "submit" && <PublishIcon className="button-icon" />}
        {icon === "download" && <DownloadIcon className="button-icon" />}
        {label}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;
