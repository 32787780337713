import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

export const CustomInput = ({
  chooseFileFunction,
  buttonStatement,
  file,
  filetype,
  style,
  disabled
}) => {
  return (
    <div>
      <Button
        id="file"
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        onChange={chooseFileFunction}
        className="primary"
        style={{ paddingLeft: "12px", width: "100%", marginBottom: "10px" }}
        disabled={disabled}
      >
        <AttachFileIcon className="button-icon" />
        {buttonStatement}
        <VisuallyHiddenInput key={file ? file.name : ""} type="file" multiple accept={filetype} />
      </Button>
    </div>
  );
};
