import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import PasswordRules from "./PasswordRules";
import PasswordTextField from "./PasswordTextField";
import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {boolean} props.open - value whether dialog window is open
 * @param {Function} props.handleClose - function to call when closing dialog
 * @param {string | {}} props.mode - either 'create' or user data to be edited as object
 * @param {Function} props.handleCreate - function to create user
 * @param {Function} props.handleEdit - function to edit user
 * @param {Object} props.passwordRules - object with rules for setting password
 * @returns  {JSX.Element} custom dialog for creating new user
 */
const UserDialog = ({
  open,
  handleClose,
  mode,
  handleCreate,
  handleEdit,
  passwordRules,
  activeUserScope
}) => {
  // i18n
  const { t } = useTranslation();
  const SCOPES = ["User", "busAdmin", "tecAdmin"];
  // form data
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userRole: activeUserScope
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: mode?.UserId ? mode.UserId : "",
      firstName: mode?.FirstName ? mode.FirstName : "",
      lastName: mode?.LastName ? mode.LastName : "",
      email: mode?.Email ? mode.Email : "",
      password: "",
      userRole: mode?.Scope ? mode.Scope : activeUserScope
    }));
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${name}`]: value
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Stack direction="column">
          {/* dialog header */}
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            {/* dialog title */}
            <Typography
              variant="h3"
              className="dialogHeadline"
              sx={{ marginLeft: "10px !important" }}
            >
              {mode === "create" ? t("create_user") : t("edit_user")}
            </Typography>

            {/* close dialog button */}
            <TooltipButton
              tooltip={`${t("close")}`}
              handleClick={handleClose}
              icon="close"
              iconStyle={{ color: "white !important" }}
              buttonClass="cardIcon"
              iconClass="cardIcon"
              ariaLabel="close current dialogue"
            />
          </Stack>
        </Stack>

        <Box
          component="form"
          noValidate
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "end"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="firstName"
                label={t("first_name")}
                value={formData.firstName}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="lastName"
                label={t("last_name")}
                value={formData.lastName}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="email"
                label={t("email")}
                value={formData.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordTextField
                required={mode === "create"}
                fullWidth
                autoComplete="new-password"
                name="password"
                label={t("password")}
                value={formData.password}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={0} display={{ xs: "block", sm: "none" }}>
              <PasswordRules rules={passwordRules} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="user-role-label">{t("role")}</InputLabel>
                <Select
                  labelId="user-role-label"
                  id="user-role"
                  name="userRole"
                  value={formData.userRole}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label={t("role")}
                  defaultValue={activeUserScope}
                >
                  {SCOPES.filter(
                    (scope) => SCOPES.indexOf(scope) <= SCOPES.indexOf(activeUserScope)
                  ).map((scope) => (
                    <MenuItem key={scope} value={scope}>
                      {scope}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} display={{ xs: "none", sm: "block" }}>
              <PasswordRules rules={passwordRules} />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              width: "max-content",
              borderRadius: "10px"
            }}
            onClick={() => {
              mode === "create" ? handleCreate(formData) : handleEdit(formData);
            }}
          >
            {mode === "create" ? t("create_user") : t("edit_user")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;
