import CustomButton from "./CustomButton";
// Button that shows a loading circle while a function is running e.g. a request is awaited for. I receives a function,
// if it is still running and what should be displayed on the button

export const LoadButton = ({
  clickFunction,
  loading,
  loadingStatement,
  buttonStatement,
  icon,
  variant,
  style,
  disabled,
  uploadState
}) => {
  return (
    <CustomButton
      variant={variant}
      onClick={clickFunction}
      disabled={loading ? true : disabled}
      label={loading ? loadingStatement : buttonStatement}
      icon={loading ? "load" : icon}
      customStyle={style}
    />
  );
};
