import {
    Autocomplete,
    Box,
    Dialog,
    DialogContent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TooltipButton from "./TooltipButton";
import { UploadFile } from "./UploadFile";

/**
 * @param {object} props
 * @param {boolean} props.open - value whether dialog window is open
 * @param {Function} props.handleClose - function to call when closing dialog
 * @param {Function} props.handleCreate - function to create user
 * @param {Function} props.handleEdit - function to edit user
 * @returns {JSX.Element} custom dialog for creating new user
 */

const UserDialog = ({
    open,
    handleClose,
    uniqueRecipients,
    handleUpload,
    handleChange,
    handleFileChange,
    loading,
    uploadState,
    files,
    filesProcessed
}) => {
    const { t } = useTranslation();

    const changeTextField = (recipient) => {
        setInputValueTextField(recipient);
        handleChange(recipient);
    };
    const handleTextChange = (recipient) => {
        setInputValueTextField(recipient);
        handleChange(recipient);
    };

    const [inputValueTextField, setInputValueTextField] = useState("");
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Stack direction="column">
                    {/* dialog header */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        {/* dialog title */}
                        <Typography
                            variant="h3"
                            className="dialogHeadline"
                            sx={{ marginLeft: "10px !important" }}
                        >
                            {t("upload_invoice")}
                        </Typography>

                        {/* close dialog button */}
                        <TooltipButton
                            tooltip={`${t("close")}`}
                            handleClick={handleClose}
                            icon="close"
                            iconStyle={{ color: "white !important" }}
                            buttonClass="cardIcon"
                            iconClass="cardIcon"
                            ariaLabel="close current dialogue"
                        />
                    </Stack>
                </Stack>

                <Box
                    component="form"
                    noValidate
                    sx={{
                        mt: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        width: "75%"
                    }}
                >
                    <Stack
                        container
                        spacing={1}
                        alignItems="left"
                        direction="column"
                        sx={{ marginLeft: "10px !important" }}
                        gap={2}
                    >
                        {t("upload_text")}
                        <Autocomplete
                            freeSolo
                            options={uniqueRecipients} // Dropdown options
                            value={inputValueTextField}
                            onChange={(event, newValue) => {
                                changeTextField(newValue || ""); // Update when selecting from dropdown
                            }}
                            inputValue={inputValueTextField}
                            onInputChange={(event, newInputValue) => {
                                handleTextChange(newInputValue); // Update when typing
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("mailbox_compcode")}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />{" "}
                        <UploadFile
                            chooseFileFunction={handleFileChange}
                            uploadFunction={handleUpload}
                            file={files}
                            loading={loading && files}
                            uploadState={uploadState}
                            filesProcessed={filesProcessed}
                        />
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default UserDialog;
