import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
      button: {
        fontSize: "15px"
      }
    },
    components: {
        MuiTableContainer: {
          styleOverrides: {
            root: {
              borderRadius: "0 !important",
            },
          },
        },
      },
  });

export default defaultTheme;